import React, {useState, useEffect} from "react";
import Square from "./Square";

const Board = ({redCount, blackCount, setRedCount, setBlackCount, turn, setTurn, movesMade, setMovesMade, gameStateString, setGameStateString}) => {
  const [squares, setSquares] = useState(Array(64).fill(""));
  const [selected, setSelected] = useState(null);
  const [moveIndex, setMoveIndex] = useState(null);
  const [gameState, setGameState] = useState(0); //0: in progress, 1: draw, 2: Black Win, 3: Red Win
  const [continuedTurn, setContinuedTurn] = useState(false);
  const [aiPlayer, setAiPlayer] = useState("r");
  const [highlighted, setHighlighted] = useState([]);

  const isSquareEmpty = (sqIndex) => squares[sqIndex] === "";
  const isOpponent = (sqIndex, color) => {
    if (sqIndex >= 0 && sqIndex < squares.length) {
      return squares[sqIndex] && squares[sqIndex][0] !== color;
    }
    return false;
  };
  
  useEffect(() => {
    let newSquares = [...squares];
    for (let i = 0; i < squares.length; i++) {
      if ((((i % 2) + (Math.floor(i / 8))) % 2 === 1)){
        if(Math.floor(i/8) < 3){ newSquares[i] = "b"; }
        else if(Math.floor(i/8) > 4){ newSquares[i] = "r"; }
      }
    }

    setSquares(newSquares);
  }, [])

  const getMultipleJumps = (index, color, factors) => {
    let mybool = [];

    factors.forEach(factor => {
      let inc = 8*factor;
      let mv = index + inc;

      if(index % 8 !== 7){
        if(((mv+1) % 8 !== 7) && isOpponent(mv + 1, color) && isSquareEmpty(mv + 2 + inc)){
          mybool.push(mv + 2 + inc);
        }
      }
      if(index % 8 !== 0){
        if(((mv-1) % 8 !== 0) && isOpponent(mv -1, color) && isSquareEmpty(mv - 2 + inc)){
          mybool.push(mv -2 + inc);
        }
      }
    });

    return mybool;
  }

  const getMoves = (index, board) => {
    let moreJumpsMain = false;
    let moreJumps = [];
    let color = null;
    try{
      color = board[index][0];
    } catch(error){
      console.log(index);
      console.log(board);
    }
    let pMoves = [];
    let factors = [];

    if(board[index][1] === "k"){ factors.push(1, -1)}
    else {color === "b" ? factors.push(1) : factors.push(-1);}

    factors.forEach(factor => {
      let inc = 8*factor;
      let mv = index + inc;

      if(index % 8 !== 7){
        if(isSquareEmpty(mv + 1) && !continuedTurn){pMoves.push(mv + 1); }
        else if(((mv+1) % 8 !== 7) && isOpponent(mv + 1, color) && isSquareEmpty(mv + 2 + inc)){
          pMoves.push(mv + 2 + inc);
          let moreJumps2 = getMultipleJumps(mv + 2 + inc, color, [...factors]);
          moreJumps.push(...moreJumps2);
          if(!moreJumpsMain){ moreJumpsMain = moreJumps.length > 0; }
        }
      }
      if(index % 8 !== 0){
        if(isSquareEmpty(mv - 1) && !continuedTurn){pMoves.push(mv - 1); }
        else if(((mv-1) % 8 !== 0) && isOpponent(mv -1, color) && isSquareEmpty(mv - 2 + inc)){
          pMoves.push(mv - 2 + inc);
          let moreJumps2 = getMultipleJumps(mv - 2 + inc, color, [...factors]);
          moreJumps.push(...moreJumps2);
          if(!moreJumpsMain){ moreJumpsMain = moreJumps.length > 0; }
        }
      }
    });

    return [pMoves, moreJumpsMain, moreJumps];
  }

  useEffect(() => {
    if(selected !== null){
      let moves = getMoves(selected, squares);
      let toHighlight = [];
      moves[0].forEach(moveIndex => {
        toHighlight.push(moveIndex);
      });
      setHighlighted(toHighlight);
    } else {if(!continuedTurn){setHighlighted([]);}}
    if(selected !== null && moveIndex !== null){
      makeMove(selected, moveIndex, false)
    }
  }, [selected, moveIndex])

  useEffect(() => {
    if(selected !== null){
      let moves = getMoves(selected, squares);
      moves[0].forEach(moveIndex => {
        
      });
    }
  }, [selected])

  const boardClick = (index) =>{
    if(squares[index][0] === turn){
      setSelected(index);
    } else if(squares[index] === ""){
      setMoveIndex(index);
    }
  }

  const makeMove = (start, end, ai) => {
    let [pMoves, moreJumps, moreJumpsarr] = [null, null, null];
    if(!ai) { [pMoves, moreJumps, moreJumpsarr] = getMoves(start, squares); }
    if(ai || pMoves.includes(end)){
      let newSquares = [...squares];
      newSquares[start] = "";
      if((squares[start] === "b" && end > 55) || (squares[start] === "r" && end < 8)){
        newSquares[end] = squares[start] + "k";
      }else{
        newSquares[end] = squares[start];
      }

      if(Math.abs(start - end) > 10){
        squares[Math.floor((start + end)/2)] === "r" ? setRedCount(prevRedCount => prevRedCount -1) : setBlackCount(prevBlackCount => prevBlackCount -1);
        newSquares[Math.floor((start + end)/2)] = "";
      }

      setSquares(newSquares);
      setSelected(null);
      setMoveIndex(null);
      setMovesMade(prevMovesMade => prevMovesMade + 1);
      if(!moreJumps){
        setTurn(turn === "b" ? "r" : "b");
        setContinuedTurn(false);
      }
      else {
        setContinuedTurn(true);
        setSelected(end);
      }
    }
  }

  const checkDraw = (board, ai = false) =>{
    let red = true;
    let black = true;

    for(let i = 0; i < squares.length; i++){
      let [pMoves, moreJumps, moreJumpsArr] = getMoves(i, board);
      if(pMoves.length > 0){
        if(squares[i] === 'r'){red = false;}
        else{black = false;}
        if(!ai){
          console.log("draw: " + pMoves);
        }
        if(!red && !black){ break; }
        return false;
      }
    }
    return (red && black);
  }

  useEffect(() => {
    gameOverCheck();
  }, [blackCount, redCount])

  useEffect(() => {
    if(movesMade < 10){return;}
    let noMoves = checkDraw(squares);
    if(noMoves){setGameState(1); }
  }, [movesMade])

  useEffect(() => {
    if(turn === aiPlayer){
      setTimeout(() => {
        makeAiMove();
      }, 20)
      
    }
  }, [turn])

  const getCounts = (board) => {
    let reds = 0;
    let blacks = 0;
    let redkings = 0;
    let blackkings = 0;
    for(let i = 0; i < board.length; i++){
      if(board[i][0] === "r"){
        reds++;
        if(board[i].length === 2){redkings++; }
      }
      else if(board[i][0] === "b"){
        blacks ++;
        if(board[i].length === 2){blackkings++; }
      }
    }

    return [reds, redkings, blacks, blackkings];
  }

  const checkWin = (board) => {
    let counts = getCounts(board);
    return (counts[0] === 0 || counts[2] === 0);
  }

  const utility = (board, moveChain) => {
    let draw = checkDraw(board, true);
    if(draw){console.log(draw); }
    let [reds, redkings, blacks, blackkings] = getCounts(board);

    if(draw && reds > blacks){ return 1000; }
    else if(draw && blacks > reds){ return -1000; }
    else if(draw && blacks === reds){ return 0; }

    if(blacks === 0){ return 1000; }
    else if(reds === 0){ return -1000; }

    let util = 0;
    util += 5*reds;
    util += -5*blacks;
    util += 3*redkings;
    util += -3*blackkings;

    return util;
  }

  const minimaxMoveMaker = (board, start, end) => {
    let newSquares = [...board];
    newSquares[start] = "";
    if((board[start] === "b" && end > 55) || (board[start] === "r" && end < 8)){
      newSquares[end] = board[start] + "k";
    }else{
      newSquares[end] = board[start];
    }

    if(Math.abs(start - end) > 10){
      newSquares[Math.floor((start + end)/2)] = "";
    }

    return newSquares;
  }

  const minimaxHelper = (board, depth, turn, move, moreJumps, moveChain) => {
    if(depth === 5 || checkWin(board)){
      let util = utility(board, moveChain);
      return util;
    }
    let optimalScore = null;
    turn === "b" ? optimalScore = Infinity : optimalScore = -Infinity;
    let pMoves = [];
    let moreJumping = false;

    let currentPieces = [];
    if(moreJumps.length > 0 && move[0] === moreJumps[0]){
      pMoves = moreJumps;
      currentPieces = move;
      moreJumping = true;
    } else {
      for(let i = 0; i < board.length; i++){
        if(board[i][0] === turn){
          currentPieces.push(i);
        }
      }
    }

    currentPieces.forEach(piece => {
      let [pMoves2, moreJumps2bool, moreJumps2] = getMoves(piece, board);
      if(!moreJumping){
        pMoves = pMoves2;
      }
      moreJumps = moreJumps2;
      if(pMoves.length > 0){
        pMoves.forEach(move => {
          let nBoard = [];
          nBoard = minimaxMoveMaker(board, piece, move);
          let jumping = move === moreJumps[0];
          let nTurn = null;
          if(turn === "b" && jumping){ nTurn = "b"; }
          else if(turn === "r" && jumping){nTurn = "r"}
          else{turn === 'b' ? nTurn = 'r' : nTurn = 'b'}
          let nMoveChain = moveChain.map(row => [...row]);
          nMoveChain.push([piece, move]);
          let depth2 = depth;
          const score = minimaxHelper(nBoard, depth+1, nTurn, move, moreJumps, nMoveChain);
          if(turn === 'b'){
            if(score < optimalScore){
              optimalScore = score;
            }
          } else {
            if(score > optimalScore){
              optimalScore = score;
            }
          }
        });
      }
    });

    return optimalScore;
  }

  const minimax = () => {
    let bestMove = [];
    let optimalScore = null;
    aiPlayer === "b" ? optimalScore = Infinity : optimalScore = -Infinity;
    console.log("START: " + optimalScore);
    let currentPieces = [];
    for(let i = 0; i < squares.length; i++){
      if(squares[i][0] === aiPlayer){
        currentPieces.push(i);
      }
    }
    console.log(currentPieces);

    for(let i = 0; i < currentPieces.length; i++){
      let [pMoves, moreJumpsbool, moreJumps] = getMoves(currentPieces[i], squares);
      console.log(currentPieces[i]);
      console.log(pMoves);
      if(pMoves.length > 0){
        pMoves.forEach(move => {
          let board = [...squares];
          board = minimaxMoveMaker(board, currentPieces[i], move);
          let jumping = move === moreJumps[0];
          let nTurn = null;
          if(aiPlayer === "b" && jumping){ nTurn = "b"; }
          else if(aiPlayer === "r" && jumping){nTurn = "r"}
          else{aiPlayer === 'b' ? nTurn = 'r' : nTurn = 'b'}
          const score = minimaxHelper(board, 1, nTurn, move, moreJumps, [[currentPieces[i], move]]);
          console.log("main score: " + score);
          if(aiPlayer === 'b'){
            if(score < optimalScore){
              optimalScore = score;
              console.log("NEW OPTIMAL: " + optimalScore);
              bestMove = [currentPieces[i], move];
            }
          } else {
            if(score > optimalScore){
              optimalScore = score;
              console.log("NEW OPTIMAL: " + optimalScore);
              bestMove = [currentPieces[i], move];
            }
          }
        });
      }
      if(bestMove === undefined){
        console.log(squares);
        console.log(pMoves)
      }
    }

    return bestMove;
  }

  const makeAiMove = () => {
    let [start, end] = minimax();
    console.log("AI Start: " + start + " AI End: " + end);
    makeMove(start, end, true);
  }

  useEffect(() => {
    switch(gameState){
      case 1:
        setGameStateString("Draw!");
        break;
      case 2:
        setGameStateString("Black Wins!");
        break;
      case 3:
        setGameStateString("Red Wins!");
        break;
      default:
        setGameStateString("In progress...");
    }
  }, [gameState])

  const gameOverCheck = () => {
    if(redCount === 1 && blackCount === 1){ setGameState(1); }
    else if(redCount === 0){ setGameState(2); }
    else if(blackCount === 0){ setGameState(3); }
  }

  return(
    <div>
      <div id="board">
        {squares.map((value, index) => (
          <Square 
          key = {index}
          onClick={() => boardClick(index)}
          val = {value}
          SqKey={((index % 2) + (Math.floor(index / 8))) % 2 ? "black": "white"}
          sqNum={index}
          highlight={highlighted.includes(index) ? "highlight" : ""}
          />
        ))}
      </div>
    </div>
  )
}

export default Board