import { useState } from 'react';
import './App.css';
import Board from "./components/Board";

function App() {
  const [redCount, setRedCount] = useState(12);
  const [blackCount, setBlackCount] = useState(12);
  const [turn, setTurn] = useState("b");
  const [movesMade, setMovesMade] = useState(0);
  const [gameStateString, setGameStateString] = useState("In Progress...");

  return (
    <div className='App'>
      <div id='title'>Checkers</div>
      <div id='main-container'>
        <div id='left-container'>
          <p id='red'>Red Count: {redCount}</p>
          <p id='blue'>Blue Count: {blackCount}</p>
          <p id='total-moves'>Total Moves: {movesMade}</p>
          <p className={`turn ${turn}`}>Turn: {turn === "b" ? "Blue" : "Red"}</p>
        </div>
        <div id='game-container'>
          <Board 
          redCount={redCount}
          blackCount = {blackCount}
          setBlackCount={setBlackCount}
          setRedCount={setRedCount}
          turn={turn}
          setTurn={setTurn}
          movesMade={movesMade}
          setMovesMade={setMovesMade}
          gameStateString={gameStateString}
          setGameStateString={setGameStateString}/>
        </div>
        <div id="game-state">
          Game State
          <p id='game-state-string'>{gameStateString}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
